// Generated by Framer (e83dae3)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withMappedReactProps } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { withActiveTag } from "../codeFile/Integrations.tsx";
import * as localizedValues from "./ObfwLuAxI-0.js";
import Tag, * as TagInfo from "./y_NL4NB3T";
const TagFonts = getFonts(Tag);
const TagWithActiveTagWithMappedReactProps123mv8p = withMappedReactProps(withActiveTag(Tag), TagInfo);

const cycleOrder = ["Dpwe91xjh"];

const serializationHash = "framer-xAGrP"

const variantClassNames = {Dpwe91xjh: "framer-v-134pdjm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Dpwe91xjh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-134pdjm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Dpwe91xjh"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-fdbkfd"} layoutDependency={layoutDependency} layoutId={"MieZhi5V8"}><ComponentViewportProvider ><motion.div className={"framer-1isssmo-container"} data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"B4Ve48kfh-container"} name={"Tag"}><TagWithActiveTagWithMappedReactProps123mv8p cqpL2a6fx={getLocalizedValue("v0", activeLocale) ?? "Offline"} height={"100%"} id={"B4Ve48kfh"} layoutId={"B4Ve48kfh"} name={"Tag"} variant={"MvZA9ix4p"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-kuekwj-container"} data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"saOfvw_mP-container"} name={"Tag"}><TagWithActiveTagWithMappedReactProps123mv8p cqpL2a6fx={"Online"} height={"100%"} id={"saOfvw_mP"} layoutId={"saOfvw_mP"} name={"Tag"} variant={"MvZA9ix4p"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xAGrP.framer-1pd7low, .framer-xAGrP .framer-1pd7low { display: block; }", ".framer-xAGrP.framer-134pdjm { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-xAGrP .framer-fdbkfd { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: row; flex-wrap: wrap; gap: 8px; height: min-content; justify-content: flex-start; max-width: 800px; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 800px; }", ".framer-xAGrP .framer-1isssmo-container, .framer-xAGrP .framer-kuekwj-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xAGrP.framer-134pdjm, .framer-xAGrP .framer-fdbkfd { gap: 0px; } .framer-xAGrP.framer-134pdjm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-xAGrP.framer-134pdjm > :first-child, .framer-xAGrP .framer-fdbkfd > :first-child { margin-left: 0px; } .framer-xAGrP.framer-134pdjm > :last-child, .framer-xAGrP .framer-fdbkfd > :last-child { margin-right: 0px; } .framer-xAGrP .framer-fdbkfd > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerObfwLuAxI: React.ComponentType<Props> = withCSS(Component, css, "framer-xAGrP") as typeof Component;
export default FramerObfwLuAxI;

FramerObfwLuAxI.displayName = "Filters - Protocol";

FramerObfwLuAxI.defaultProps = {height: 26, width: 800};

addFonts(FramerObfwLuAxI, [...TagFonts])